<template>
  <b-overlay :show="loading">
    <div
      class="w-full h-full d-flex flex-column align-items-center justify-content-center"
      style="min-height: 100vh; justify-content: center; align-items: center"
    >
      <div class="text-center">
        <h1>Logging in with apple...</h1>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import {
  MUTATE_LOGIN_STATUS,
  MUTATE_USER_DATA,
} from "@/store/config/mutation-keys";
import { BOverlay } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "AppleOAuthCallbackPage",
  components: {
    BOverlay,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    const baseUrl = new URL(window.location);
    const urlParams = new URLSearchParams(baseUrl.hash);
    const hasValidState = this.verifyOauthState();

    const code = urlParams.get("code");

    if (!code || !hasValidState) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          text: "Error login in with your google account. Try again later",
          icon: "AlertTriangleIcon",
          variant: "danger",
          title: `Google Login Error`,
        },
      });
      return;
    }
    this.loginWithApple(code);
  },
  methods: {
    async loginWithApple(code) {
      try {
        console.log("APPLE LOGIN CODE", code)
        this.loading = true;

        const response = await this.useJwt().authService.loginWithGoogle({
          code,
        });

        const response_data = response.data.data;
        const { mfa_auth_required } = response_data;

        if (mfa_auth_required) {
          const { mfa_access_token, mfa_method } = response_data;
          this.useJwt().mfaService.setMfaToken(mfa_access_token);

          this.$router
            .push({
              name: "security-mfa-authentication",
              params: { mfa_method },
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Set-up multi factor authentication",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: "Kindly secure your account",
                },
              });
            })
            .catch(() => {});
        } else {
          const { access_token, user } = response_data;
          this.useJwt().authService.setToken(access_token);
          this.$store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
          this.$store.commit(`auth/${MUTATE_USER_DATA}`, user);

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Welcome ${user.first_name || user.last_name}`,
              icon: "CheckCircleIcon",
              variant: "success",
              text: `You have successfully logged in.`,
            },
          });
          this.redirectToLastPage();
        }
      } catch (error) {
        console.log(error);
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.error = error_message;
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
